body {
    color: #464646;
    font-family: Arial, serif;
}

.mt7 {
    margin-top: 7px !important;
}

.mt23{
    margin-top: 23px;
}

.mb25 {
    margin-bottom: 25px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mr15 {
    margin-right: 15px !important;
}

.p25 {
    padding: 25px !important;
}

.f-s-14 {
    font-size: 14px !important;
}

.f-s-12 {
  font-size: 12px!important;
}

.f-s-11 {
    font-size: 11px!important;
}

.f-s-9 {
    font-size: 9px !important;
}

.f-s-18{
    font-size: 18px;
}

.text-ellipsis-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-blue {
    color: #3EC3D5;
}

.text-green {
    color: #26A693 !important;
}

.text-grey {
    color: rgba(70, 70, 70, 0.5);
}

.text-black {
    color: #000 !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt30 {
    margin-top: 30px!important;
}

.mt25 {
    margin-top: 25px!important;
}

.ml10{
    margin-left: 10px;
}

.mt17{
    margin-top: 17px!important;
}

.mb33{
    margin-bottom: 33px;
}

.h100 {
    height: 100px;
}

.w100 {
    width: 100% !important;
}

.bg-green {
    background-color: #19af95 !important;
}

.bg-green:hover {
    background-color: #fff !important;
    border: 1px solid #19af95 !important;
    color: #19af95 !important;
}

.btn-green {
    background-color: #19af95 !important;
    color: #fff !important;
}


/*.btn-green:hover{*/
/*border: 1px solid #19af95!important;*/
/*background-color: #fff!important;*/
/*color: #000!important;*/
/*}*/
.btn-out-green {
    border: 1px solid #19af95 !important;
    color: #000 !important;
    background-color: #fff !important;
}

/*
.btn-out-green:hover{
  background-color: #19af95!important;
  color: #fff!important;

}
*/

.text-white {
    color: #fff;
}

.mainMenu {
    list-style-type: none;
}

.mainMenu li {
    float: left;
    margin-left: 5px;
}

.mainMenu li a {
    color: #000;
}

.profileMenu {
    list-style-type: none;
}

.profileMenu li {
    float: left;
    margin-left: 15px;
}

.profileMenu a {
    color: #000;
}

.regBtn {
    margin-top: -7px;
}

.banner {
    background-image: url("assets/images/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    height: 100%;

}


.vis-hidden {
    visibility: hidden !important;
}

.sections {
    margin-top: -90px;
    margin-bottom: 60px;
}

.sections p {
    margin-bottom: 0;
}

.sections img {
    height: 40px;
    object-fit: cover;
}


.doctorsSection {
    background-color: #245AA3;
    cursor: pointer;
}

.labSection {
    background-color: #E23E4F;
}

.pharmSection {
    background-color: #26A693;
}

.Toastify__toast--success {
    background: #19af95 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-red {
    background-color: #E23E4F !important;
}

.text-red {
    color: #E23E4F !important;
}

.autocompleteList {
    list-style-type: none;
}

.autocompleteList li {
    padding: 5px 0;
    background-color: #f3f3f3;
}

.autocompleteList li.active, .autocompleteList li:hover {
    background-color: #cccccc;
}

.loaderDiv {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    overflow: auto;
    justify-content: center;
    z-index: 1000;
}

.menu {
    background: rgba(249, 249, 249, 0.94);
    box-shadow: 0 -0.5px 0 rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    height: 83px;
    padding-top: 25px;
}

.menu .col {
    text-align: center;
}

.menu a.active, .menu a:hover, .menu a:active {
    color: #6ACEF6;
}

.forPB {
    padding-bottom: 145px;
}

.v-hidden {
    visibility: hidden !important;
}

.doctorImage {
    height: 110px;
    object-fit: cover;
}

.sortingDiv {
    margin-top: 10px;
    height: 3rem;
    align-items: center;
    /* justify-content: flex-end;*/
}

.sortingDiv span {
    font-size: 20px;
}

.sortingDiv i {
    color: #929398;
}

.sortingDiv span.active i {
    color: #26A693;
}

.filtersSide .react-datepicker-wrapper {
    width: 100%;
}

.pharmInSlider img {
    height: 80px !important;
    object-fit: cover;
}

.react-datepicker-wrapper {
    width: 100%;
}


.selectedServices {
    right: 0;
    height: 50px;
    width: 50px;
    top: 50px;
    background: #26A693;
    color: #fff;
    display: flex;
    justify-content: center;
    font-weight: bolder;
    align-items: center;
}


.z-index-1000 {
    z-index: 1000;
}

.titleBlock {
    background-color: #26A693;
    color: #fff;
    font-size: 18px;
    font-weight: bolder;
    cursor: pointer;
}

.subTitleBlock {
    color: #fff;
    font-size: 14px;
    font-weight: bolder;
    cursor: pointer;
    background-color: #606063;
}

.create-card {
    margin-top: 50px;
}

.logoString {
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    /* identical to box height, or 121% */

    letter-spacing: 0.374px;
    color: #000;
    padding-top: 88px;
}

.logoString a, .logoString a:hover {
    color: #000;
    text-decoration: none;
}

.slideDiv {
    padding-right: 30px;
}

.slideItemTitle {
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #464646;
    margin-top: 8px;
}

.sectionTitle {
    font-size: 12px;
    line-height: 14px;
    color: #464646;
}

.slideAllLink {
    font-size: 9px;
    line-height: 10px;
    text-align: center;
    color: #3DB2FF;
    margin-top: 10px;
}

.menuTitle {
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    color: #3DB2FF;
    margin-top: 7px;
}

.lgIcons i {
    font-size: 18px;
    color: rgba(70, 70, 70, 0.5);
}

.lgIcons i.active {
    color: #65D9D2;
}

.w100px {
    width: 100px;
}

.mt50 {
    margin-top: 50px;
}

a:hover {
    text-decoration: none !important;
}

.mt8 {
    margin-top: 8px;
}

.btn-orange {
    background-color: #FFAF37 !important;
    border-radius: 7px !important;
}

.btn-blue {
    background-color: #3EC3D5 !important;
    border-radius: 15.5px!important;
    color: #fff!important;
}

.btn-white {
    background-color: #fff !important;
    border-radius: 15.5px!important;
    color: #3EC3D5!important;
    border: 1px solid #3EC3D5!important;
}

.mt--15 {
    margin-top: -15px;
}

.searchIcon {
    margin-left: -45px;
}

.w50 {
    width: 50px;
}

.mt15rem {
    margin-top: 1.5rem;
}

.w20 {
    width: 20px;
}

.doctorName {
    background: rgba(255, 255, 255, 0.54);
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 0px 0px 6px 6px;
    color: #464646;
    bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 11px;
    width: 100%;
    padding: 10px;
}

.doctorInfo {
    background: rgba(255, 255, 255, 0.74);
    box-shadow: 0px 9px 11px -8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    padding-bottom: 13px;
}

.readMore {
    background: #464646;
    border-radius: 4px;
    margin: 0 auto;
    width: 82px;
    height: 3px;
    bottom: 13px;
}

.h250 {
    max-height: 250px;
    overflow: hidden;
    padding-bottom: 13px;
}

.contacts img {
    height: 25px;
    object-fit: cover;
}

.btn-purple {
    background: linear-gradient(268.6deg, #3DB2FF -26.4%, #7F74FF 36.48%, #5AB9F9 98.07%, #5AB9F9 98.07%);
    box-shadow: -2px 18px 10px -14px rgba(0, 0, 0, 0.2);
    border-radius: 15px !important;
    color: #fff !important;
}

.serviceList {
    margin-top: 50px;
}

.serviceList .oneDoctorService {
    margin-bottom: 20px;
}

.serviceList input {
    margin-right: 7px;
}

.serviceList label {
    color: #464646;
    font-size: 14px;
    line-height: 24px;
}

.serviceList .custom-control-label::before {
    border: 2px solid #3DB2FF;
}

.serviceList .custom-radio .custom-control-input:checked ~ .custom-control-label {
    color: #3DB2FF;
}

.calendarBlock {
    margin-top: 15px;
    box-shadow: 0px 14px 11px -10px rgba(0, 0, 0, 0.17);
    padding-bottom: 10px;
}

.calendarBlock abbr[title] {
    color: #3DB2FF;
    text-decoration: none;
}

.calendarBlock .react-calendar {
    border: none;
}

.calendarBlock .react-calendar__month-view__days__day--weekend {
    color: red;
    opacity: 0.5;
}

.calendarBlock .react-calendar__tile--active {
    background-color: #3DB2FF;
}

.calendarBlock .react-calendar__tile {
    font-size: 12px;
}

.oneTime {
    margin-bottom: 20px;
    cursor: pointer;
}

.oneTime.disabled {
    color: #5D5D5D;
    opacity: 0.5;
}


.doctorTimes .oneTime {
    font-size: 12px;
}

.oneTime.active {
    background-color: #3DB2FF;
    color: #fff;
    border-radius: 5px;
}

.selectedDates p {
    color: #5D5D5D;
}

.timesBlock {
    box-shadow: 0px 14px 11px -10px rgba(0, 0, 0, 0.17);
}

.callForm input {
    margin-bottom: 15px;
}

.react-calendar__tile:disabled {
    background-color: #fff !important;
}


.callInfo {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
    background: #fff;
}

.f-s-10 {
    font-size: 10px !important;
}
